// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var footerContainer = "D_cg";
export var footerMenu = "D_ch";
export var menuItem = "D_br";
export var orderHorizontal = "D_cj";
export var orderVertical = "D_ck";
export var itemSeparator = "D_cl";
export var blankSeparator = "D_cm";